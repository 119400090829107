@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-montserrat uppercase text-[#031555] xl:text-[12px] 2xl:text-[14px];
  }
  a {
    @apply font-montserrat hover:text-customPink;
  }
  button {
    @apply uppercase px-6 py-2 rounded-[50px] lg:hover:scale-[1.02] text-[12px] xs:text-[10px];
  }
  h1 {
    @apply text-[20px] md:text-[32px] lg:text-[40px] 2xl:text-[48px] text-center;
  }
  h2 {
    @apply sm:text-[18px] md:text-[18px] xl:text-[20px] text-center;
  }
  label {
    @apply normal-case text-[14px] text-red-500;
  }
}

@layer components {
  .sign input {
    @apply h-10 rounded-[20px] p-6 border border-solid border-slate-400 focus:outline-none focus:border-customPink lg:h-16;
  }
  .fb {
    @apply flex items-center justify-center bg-customPink h-10 md:w-[60%] lg:w-full hover:text-white px-6 py-2 rounded-[50px] lg:hover:scale-[1.02] text-[12px] xs:text-[10px];
  }
  .vert {
    @apply w-full md:h-[290px] lg:w-[362px] lg:h-[321px];
  }
  .vertArrow {
    @apply md:relative w-20 h-20 xl:left-[30%] -top-10 md:left-[10%] lg:left-0;
  }
  .vertArrow-1 {
    @apply md:relative w-20 h-24 xl:right-[30%] -top-10 md:right-[7%] md:-top-10 lg:right-0;
  }
  thead tr th:first-child,
  tbody tr td:first-child {
    @apply min-w-[50vw] text-left md:min-w-full;
  }
  tr th {
    @apply text-[12px] text-center px-2 py-6 text-white first:text-[#031555] first:lg:text-[16px] first:lg:px-0;
  }
  tr th:nth-child(2) {
    @apply bg-[#031555] rounded-t-md;
  }
  tr th:nth-child(3) {
    @apply bg-[#1E62F3] rounded-t-md;
  }
  tr th:nth-child(4) {
    @apply bg-[#208EF0] rounded-t-md
  }
  tr td:nth-child(2) {
    @apply text-[#031555]
  }
  tr td:nth-child(3) {
    @apply text-[#1E62F3]
  }
  tr td:nth-child(4) {
    @apply text-[#208EF0]
  }
  tr td:nth-child(2) {
    @apply border-x border-[#031555] border-solid;
  }
  tr td:nth-child(3) {
    @apply border-x border-[#1E62F3] border-solid;
  }
  tr td:nth-child(4) {
    @apply border-x border-[#208EF0] border-solid;
  }
  tr:last-child td {
    @apply rounded-b-md border-b;
  }
  tr:last-child td:first-child {
    @apply border-b-0;
  }
  tr td {
    @apply text-[12px] border-t text-center py-2 font-semibold;
  }
  .card:nth-child(1) {
    @apply text-[#031555];
  }
  .card:nth-child(2) {
    @apply text-[#4A8CD1];
  }
  .card:nth-child(3) {
    @apply text-[#7F3292];
  }
  .card:nth-child(3) h1 {
    @apply text-[16px] font-extrabold p-4;
  }
  .card:hover:nth-child(1) {
    @apply bg-[#031555] text-white;
  }
  .card:hover:nth-child(2) {
    @apply bg-[#4A8CD1] text-white;
  }
  .card:hover:nth-child(3) {
    @apply bg-[#7F3292] text-white;
  }
  .card:nth-child(1) {
    @apply text-[#031555];
  }
  .card:nth-child(1) a {
    @apply border border-solid border-current bg-[#031555] text-white;
  }
  .card:nth-child(2) a {
    @apply border border-solid border-current bg-[#4A8CD1] text-white;
  }
  .card:nth-child(3) a {
    @apply border border-solid border-current bg-[#7F3292] text-white;
  }
  .card:hover:nth-child(1) a {
    @apply border border-solid border-current bg-[white] text-[#031555];
  }
  .card:hover:nth-child(2) a {
    @apply border border-solid border-current bg-[white] text-[#4A8CD1];
  }
  .card:hover:nth-child(3) a {
    @apply border border-solid border-current bg-[white] text-[#7F3292];
  }
  .footer-row {
    @apply flex flex-col text-[12px] lg:text-[14px] font-medium lg:space-y-1.5 lg:mt-6 normal-case;
  }
  .request {
    @apply border rounded-lg p-2 border-slate-400 focus:outline-none w-full
  }
}

@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

.elements {
  align-items: center;
  position: absolute;
  justify-content: center;
  top: 20px;
  z-index: 100;
  height: 50px;
}

.elements .swiper-pagination-bullet {
  display: flex;
  width: auto;
  height: auto;
  padding: 10px 12px;
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
  opacity: 1;
  background: white;
  font-size: 0.7vw;
  font-weight: 700;
}

.elements .swiper-pagination-bullet:hover {
  background: #01a5f9;
  color: #fff;
}

.bot-elements .swiper-pagination-bullet:hover {
  background: #01a5f9;
  color: #fff;
}

.elements .swiper-pagination-bullet-active {
  color: #fff;
  background: #01a5f9;
}

.bot-elements {
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60px;
  z-index: 100;
  height: 50px;
}

.bot-elements .swiper-pagination-bullet {
  display: flex;
  width: auto;
  height: auto;
  padding: 10px 12px;
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
  opacity: 1;
  background: white;
  font-size: 0.7vw;
  font-weight: 700;
}

.bot-elements .swiper-pagination-bullet-active {
  color: #fff;
  background: #01a5f9;
}

.tuts .swiper-pagination {
  position: initial;
  margin-top: 20px;
}

.tuts .swiper-pagination {
  position: initial;
  margin-top: 20px;
}

.tuts .swiper-pagination-bullet-active {
  width: 20px;
  border-radius: 25px;
  background-color: #031555;
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1279px) {
  .bot-elements .swiper-pagination-bullet {
    display: none;
  }
}

.talkbubbletl {
  height: 50px;
  font-size: 14px;
  position: absolute;
  -moz-border-radius: 10px 10px 0px 10px;
  -webkit-border-radius: 10px 10px 0px 10px;
  border-radius: 10px 10px 0px 10px;
  padding: 0px 6px;
  align-items: center;
  color: white;
  background-color: rgb(1, 165, 249);
}

.talkbubbletl:before {
  content: "";
  position: absolute;
  left: 100%;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 6px solid rgb(1, 165, 249);
  border-top: 10px solid transparent;
}

.talkbubblebl {
  width: 140px;
  height: 60px;
  font-size: 10px;
  text-transform: none;
  font-weight: 600;
  position: absolute;
  -moz-border-radius: 10px 10px 0px 10px;
  -webkit-border-radius: 10px 10px 0px 10px;
  border-radius: 10px 10px 0px 10px;
  padding: 0px 6px;
  align-items: center;
  color: black;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.talkbubblebl:before {
  content: "";
  position: absolute;
  left: 100%;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 6px solid white;
  border-top: 10px solid transparent;
}

.talkbubbletr {
  height: 50px;
  font-size: 12px;
  color: white;
  position: absolute;
  -moz-border-radius: 10px 10px 10px 0px;
  -webkit-border-radius: 10px 10px 10px 0px;
  border-radius: 10px 10px 10px 0px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  background-color: rgb(1, 165, 249);
}

.talkbubbletr:before {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-bottom: 10px solid rgb(1, 165, 249);
}

.swiper-slide {
  height: auto !important;
}

/* .swiper .swiper-wrapper {
    height: 50%
 } */
